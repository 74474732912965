import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Typography, Switch, Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import constants from '../../utilities/constants';
import { updatePreferences } from '../../redux/slices/notifications';
import i18n from '../../services/i18n';

const preferencesList = constants.NOTIFICATION_PREFERENCES_LIST;

const Index = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const currentUserPreferences = informations?.notificationPreferences;
  const isArabic = i18n.language === 'ar';
  const groupedPreferences = preferencesList.reduce((acc, pref) => {
    if (!acc[pref.family]) {
      acc[pref.family] = [];
    }
    acc[pref.family].push(pref);
    return acc;
  }, {});
  const [expandedFamily, setExpandedFamily] = useState(null);

  return (
    <Formik
      initialValues={{
        ...preferencesList.reduce((acc, pref) => {
          acc[pref.name] = currentUserPreferences?.[pref.name] ? 1 : 0;
          return acc;
        }, {}),
      }}
      validationSchema={Yup.object().shape({})}
    >
      {({ values, setFieldValue }) => (
        <div className={`preferences-list ${isArabic ? 'rtl' : ''}`}>
          <p>{t('Modify your notification settings')}</p>
          {Object.keys(groupedPreferences).map((family) => (
            <div className="collapse-container" key={family}>
              <div className="collapse-title">
                <Typography className="family-title" variant="h6">
                  {t(family)}
                </Typography>
                <IconButton
                  onClick={() => setExpandedFamily(expandedFamily === family ? null : family)}
                >
                  {expandedFamily === family ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedFamily === family}>
                {groupedPreferences[family].map((preference) => (
                  <div className="preference-container" key={preference.id}>
                    <Typography
                      className="preference-description"
                      variant="body2"
                      color="textSecondary"
                    >
                      {t(preference?.value)}
                    </Typography>
                    <Switch
                      checked={values[preference.name]}
                      color="primary"
                      edge="start"
                      name={preference.name}
                      onChange={(e) => {
                        const newValue = e.target.checked === true ? 1 : 0;
                        setFieldValue(preference.name, newValue);

                        const updatedValues = {
                          ...values,
                          [preference.name]: newValue,
                        };
                        dispatch(updatePreferences(updatedValues));
                      }}
                    />
                  </div>
                ))}
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </Formik>
  );
};

export default Index;
