import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as dayjs from 'dayjs';
import NoDataIllustration from '../NoDataIllustration';
import noNotificationIllustration from '../../assets/img/noNotificationIllustration.webp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Loader } from '..';
import i18n from '../../services/i18n';
import { updateNotificationARead } from '../../redux/slices/notifications';
import constants from '../../utilities/constants';

const Index = ({ notifications, handleClose, loadMore, hasMore, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const notificationIcons = constants.NOTIFICATIONS_ICONS;

  const handleNotficiationOnClick = (notificationId) => {
    dispatch(updateNotificationARead(notificationId));
  };
  if ((!notifications || notifications?.length === 0) && isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}
      >
        <CircularProgress style={{ color: '#2ba7df', height: '30px', width: '30px' }} />
      </div>
    );
  }
  return (
    <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<Loader key={0} />}
        useWindow={false}
      >
        {notifications && notifications.length ? (
          notifications.map((notification, index) => {
            const notificationIcon = notificationIcons?.find(
              (icon) => icon?.title === notification?.title
            );
            return (
              <MenuItem
                key={index}
                onClick={handleClose}
                style={{ backgroundColor: !notification.seen && 'rgba(233, 248, 253, 0.6)' }}
              >
                <Link
                  to={notification?.link || '#'}
                  key={notification.id}
                  onClick={() => handleNotficiationOnClick(notification.id)}
                  className={`notifications-list ${isArabic ? 'rtl' : ''}`}
                >
                  <img alt={notificationIcon?.title} src={notificationIcon?.icon} />
                  <div className="notification-wrapper ">
                    <Typography className="notification-body">{t(notification?.body)}</Typography>
                    <Typography className="notification-time">
                      {dayjs(notification.created_at).format('hh:mm')}
                    </Typography>
                  </div>
                </Link>
              </MenuItem>
            );
          })
        ) : (
          <NoDataIllustration
            image={noNotificationIllustration}
            text={t('Pas de notifications')}
            title="noNotificationIllustration"
          />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Index;
