import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { enqueueSnackbar } from './notifier';
import i18n from '../../services/i18n';

const slice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    unreadNotifications: [],
    preference: '',
    isNotificationsLoading: false,
    isUnreadNotificationsLoading: false,
    isPreferenceLoading: false,
    error: false,
    hasMore: false,
    hasErrorUnreadNotif: false,
    hasMoreUnread: false,
    unreadNotificationsCount: 0,
    updateUnreadNotifsLoading: false,
    messageSuccess: '',
  },
  reducers: {
    startPreferenceLoading: (state) => {
      state.isPreferenceLoading = true;
    },
    hasPreferenceError: (state, action) => {
      state.error = action.payload;
      state.isPreferenceLoading = false;
    },
    preferencesSuccess: (state, action) => {
      state.preference = action.payload;
      state.isPreferenceLoading = false;
    },
    startLoading: (state) => {
      state.isNotificationsLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isNotificationsLoading = false;
    },
    notificationsSuccess: (state, action) => {
      const { notifications, currentPage, hasMore } = action.payload;
      if (currentPage === 1) {
        state.notifications = notifications;
      } else {
        state.notifications = [...state.notifications, ...notifications];
      }
      state.hasMore = hasMore;
      state.isNotificationsLoading = false;
    },
    startUnreadNotificationsLoading: (state) => {
      state.isUnreadNotificationsLoading = true;
    },
    hasErrorUnreadNotif: (state, action) => {
      state.error = action.payload;
      state.isUnreadNotificationsLoading = false;
    },
    unreadNotificationsSuccess: (state, action) => {
      const { unreadNotifications, currentPage, hasMore } = action.payload;
      if (currentPage === 1) {
        state.unreadNotifications = unreadNotifications;
      } else {
        state.unreadNotifications = [...state.unreadNotifications, ...unreadNotifications];
      }
      state.hasMoreUnread = hasMore;
      state.isUnreadNotificationsLoading = false;
    },
    unreadNotificationsCountSuccess: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },
    hasErrorUnreadNotifCount: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },

    startUpdateNotificationsLoading: (state) => {
      state.updateUnreadNotifsLoading = true;
    },
    updateAllNotificationsSuccess: (state, action) => {
      state.updateUnreadNotifsLoading = false;
      messageSuccess = action.payload;
    },
    hasUpdateNotificationsError: (state, action) => {
      state.updateUnreadNotifsLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const {
  startPreferenceLoading,
  preferencesSuccess,
  hasPreferenceError,
  startLoading,
  hasError,
  notificationsSuccess,
  startUnreadNotificationsLoading,
  hasErrorUnreadNotif,
  unreadNotificationsSuccess,
  unreadNotificationsCountSuccess,
  hasErrorUnreadNotifCount,
  startUpdateNotificationsLoading,
  updateAllNotificationsSuccess,
  hasUpdateNotificationsError,
} = slice.actions;

export const updatePreferences = (values) => async (dispatch) => {
  dispatch(startPreferenceLoading());
  try {
    await api.put(`/user/update-notification-preferences`, values).then((response) => {
      dispatch(preferencesSuccess(response.data));
      const message = response?.data?.message;
      dispatch(
        enqueueSnackbar({
          message: i18n.t(message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
    });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(
        enqueueSnackbar({
          message: i18n.t(message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        })
      );
      return dispatch(hasPreferenceError(message));
    }
  }
};

export const getAllNotifications =
  (page = 1) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await api.get(`/student/notifications?page=${page}`).then((response) => {
        const meta = response.data.meta;

        const hasMore = meta.current_page < meta.last_page;
        dispatch(
          notificationsSuccess({
            notifications: Array.isArray(response.data.payload) ? response.data.payload : [],
            currentPage: meta.current_page,
            hasMore,
          })
        );
      });
    } catch (e) {
      return dispatch(hasError(e.message));
    }
  };

export const getAllUnreadNotifications =
  (page = 1) =>
  async (dispatch) => {
    dispatch(startUnreadNotificationsLoading());
    try {
      await api.get(`/student/notifications??search=seen:false&page=${page}`).then((response) => {
        const meta = response.data.meta;

        const hasMore = meta.current_page < meta.last_page;
        dispatch(
          unreadNotificationsSuccess({
            unreadNotifications: Array.isArray(response.data.payload) ? response.data.payload : [],
            currentPage: meta.current_page,
            hasMore,
          })
        );
      });
    } catch (e) {
      return dispatch(hasErrorUnreadNotif(e.message));
    }
  };

export const getUnreadNotificationsCount = () => async (dispatch) => {
  try {
    const response = await api.get(`/student/notifications?search=seen:false`);

    const totalUnreadCount = response.data.meta?.total || 0;

    dispatch(unreadNotificationsCountSuccess(totalUnreadCount));
  } catch (e) {
    return dispatch(hasErrorUnreadNotifCount(e.message));
  }
};

export const updateNotificationARead = (notificationId) => async (dispatch) => {
  dispatch(startUpdateNotificationsLoading());
  try {
    await api.post(`/student/notifications/${notificationId}/mark-as-seen`).then((response) => {
      dispatch(getUnreadNotificationsCount());
      dispatch(updateAllNotificationsSuccess(response.data));
    });
  } catch (e) {
    if (e.response) {
      return dispatch(hasUpdateNotificationsError(e.message));
    }
  }
};
